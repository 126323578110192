@use "global" as g; 

//================
// News Archive
//================
#news_wrapper {
	@include g.gradebg();
}
#news_sec {
	.blockwrap {
		@include g.flex();
	}
	.title_blc {
		width: 35%;
	}
	.news_list {
		width: 65%;
		margin-top: var(--gap);
	}
	.title {
		.petal:before {
			transform: rotate(-60deg);
		}
	}
	#cat_list {
		line-height: 0;
		li {
			margin-bottom: 2rem;
		}
		a {
			padding: 0.8rem 3rem;
			font-size: 1.2rem;
			line-height: 1;
			text-align: center;
			background: var(--beige_light2);
			&:hover {
				color: var(--beige_light);
				background: var(--nansui_bk);
			}
		}
	}
}
@include g.mq(sp) {
	#news_sec {
		.title_blc {
			width: 100%;
		}
		.news_list {
			width: 100%;
			margin-top: var(--gap2);
		}
		.title {
			text-align: center;
		}
		#cat_list {
			li {
				display: inline-block;
				margin-right: 1rem;
				margin-bottom: 1rem;
			}
			a {
				padding: 0.5rem 3rem;
			}
		}
	}
}

/* ***** pagination **************** */
#pagination {
	width: 100%;
	padding-top: var(--sec);
	line-height: 1;
	text-align: center;
	#pagenav {
		@include g.flex_center();
	}
	a {
		@include g.flex_center();
	}
	.nav-links {
		@include g.flex_center();
		a,span {
			padding: 1rem;
		}
		a:hover {
			color: var(--nansui_red);
			path {
				fill: var(--nansui_red);
			}
		}
	}
	.prev svg {
		margin-right: 1rem;
		transform: rotate(180deg);
	}
	.next svg {
		margin-left: 1rem;
	}
	.back {
		margin: 0 4rem;
	}
}


/* ===== news item ===== */
.news_list {
	&:before {
		z-index: 1;
	}
	.news_item {
		a {
			@include g.flex();
			align-items: center;
			padding: 1rem 0;
			&:hover {
				background: #fff;
			}
		}
		.thumb {
			width: 30%;
			max-width: 24rem;
			margin-right: 4rem;
		}
		.txt_blc {
			flex: 1;
			.news_title {
				margin-top: 1rem;
				line-height: 1.4;
			}
		}
	}
}
@include g.mq(sp) {
	.news_list {
		.news_item {
			a {
				align-items: stretch;
			}
			.thumb {
				width: 10rem;
				margin-right: 2rem;
			}
		}
	}
}


/* ===== tags ===== */
.news_tags {
	display: flex;
	align-items: center;
	font-size: 1.2rem;
	line-height: 1;
	letter-spacing: 0;
	white-space: nowrap;
	.cat {
		margin-left: 1rem;
		padding: 0.4em 2em;
		background: var(--beige_light2);
	}
	@include g.mq(sp) {
		margin-bottom: 1rem;
		font-size: 1rem;
	}	
}




/* ***** Single **************** */
#news {

#news_sec {
	#contents_blc {
		width: 65%;
		margin-top: var(--gap2);
		h1 {
			font-size: 2.6rem;
			line-height: 1.6;
			margin-bottom: 2rem
		}
		.cat {
			&:hover {
				color: var(--beige_light);
				background: var(--nansui_bk);
			}
		}
		.thumb {
			width: 100%;
			margin: var(--gap2) 0;
		}
	}
}
@include g.mq(sp) {
	#news_sec {
		#cat_list {
			display: none;
		}
		#contents_blc {
			width: 100%;
			margin-top: 0;
			h1 {
				font-size: 2.2rem;
				margin-bottom: 2rem
			}
			.thumb {
				width: 100%;
				margin: var(--gap2) 0;
			}
		}
	}
}


/* ***** Contents **************** */
#single_contents {
	h1,h2,h3,h4,h5,h6,strong {
		font-size: 2.2rem;
		font-weight: 700;
		@include g.mq(sp) {
			font-size: 1.8rem;
		}
	}
}


/* ***** Other post **************** */
#other_post {
	padding-top: 0;
	.title {
		width: 100%;
		margin-bottom: var(--gap);
		text-align: center;
	}
	.news_item {
		.thumb {
			width: 100%;
		}
		.news_tags {
			margin-top: 2rem;
			margin-bottom: 1rem;
			.cat {
				background: var(--beige_light);
			}
		}
		h3 {
			margin-bottom: 2rem;
			line-height: 1.6;
		}
		&:after {
			transform: scale(0,1);
		}
		&:hover:after {
			transform: scale(1);
		}
	}
}
@include g.mq(sp) {
	#other_post {
		overflow: hidden;
		#postSwiper {
			overflow: visible;
		}
	}
}


}









