@use "global" as g; 

//================
// Loading
//================
#loading {
	width: 100%;
	height: 100dvh;
	position: fixed;
	top: 50%;
	left: 0;
	z-index: 9999;
	transform: translate(0,-50%);
	pointer-events: none;
	body.loading & {
		display: none;
	}
	#loading_bg {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: var(--nansui_bk);
	}
	#load_logo_red {
		width: 100%;
		height: 50%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		#load_red_bg {
			width: 100%;
			height: 100%;
			background: var(--nansui_red);
		}
		#load_logo_red_svg {
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}
	#load_logo_bk {
		width: 100%;
		height: 50%;
		position: absolute;
		bottom: 0;
		left: 0;
		background: var(--nansui_bk);
	}
	#load_logo_wh {
		width: 100%;
		position: absolute;
		bottom: 50%;
		left: 0;
		z-index: 10;
		transform: scale(1,0);
	}
}


//================
// Top
//================
#top {
/* ***** kv ********* */
#kv {
	position: relative;
	width: 100%;
	height: 100vh;
	height: 100svh;
	padding: 0;
	color: var(--beige_light);
	#kv_title {
		position: absolute;
		top: 116px;
		left: 40px;
		font-family: var(--ff_zenmincho);
		font-size: 2rem;
		line-height: 1.6;
		text-shadow: 2px 2px 4px rgba(0,0,0,0.6);
		strong {
			font-size: 2.8rem;
		}
	}
	#kv_footer {
		width: 100%;
		position: absolute;
		bottom: 2rem;
		left: 0;
		font-family: var(--ff_zenmincho);
		font-size: 1.8rem;
		text-align: center;
		line-height: 1;
		letter-spacing: 0.3em;
		text-shadow: 2px 2px 4px rgba(0,0,0,0.6);
	}
}
@include g.mq(sp) {
	#kv {
		#kv_title {
			top: 65px;
			left: var(--side);
			font-size: 1.4rem;
			strong {
				font-size: 2.2rem;
			}
		}
		#kv_footer {
			bottom: 2rem;
			font-size: 1.4rem;
		}
	}	
}


/* ***** Recent news ********* */
#recent_news {
	padding: 0;
	border-bottom: 1px solid var(--beige_light2);
	.news_item a {
		display: flex;
		align-items: center;
		padding: 1rem var(--side);
		line-height: 1.4;
		background: #fff;
		&:hover {
			background: var(--beige_light);
		}
		.news_tags {
			margin-right: 2rem;
		}
	}
	@include g.mq(sp) {
		.news_item a {
			display: block;
			.news_tags {
				width: 100%;
			}
			.news_tit {
				width: 100%;
			}
		}
	}
}


/* ***** Concept **************** */
#top_concept {
	line-height: 2.2;
	text-align: center;
	overflow: hidden;
	@include g.gradebg();
	.title,
	.concept_txt {
		position: relative;
		z-index: 1;
	}
	.title .petal:before{
		margin: 0 auto;
		transform: rotate(30deg);
	}
	.bg {
		content: '';
		display: block;
		width: 160%;
		height: 100%;
		position: absolute;
		left: 2%;
		bottom: 2%;
		background-position: left center;
		background-repeat: no-repeat;
		background-size: contain;
		pointer-events: none;
	}
	.concept_txt {
		font-family: var(--ff_zenmincho);
		font-size: 1.6rem;
	}
	.moreblc {
		margin-top: var(--sec);
	}
}
@include g.mq(sp) {
	#top_concept .bg {
		width: 120%;
		left: 50%;
		bottom: 45%;
		transform: translate(-50%,50%);
	}
	#top_concept {
		html[lang=en-US] & {
			letter-spacing: 0;
		}
	}
}


/* ***** Room **************** */
#top_room {
	@include g.gradebg();
	.blockwrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.title .petal:before {
		transform: rotate(-60deg);
	}
	.txt_blc {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 40%;
		padding-right: var(--side);
	}
	.moreblc {
		margin-top: 2rem;
		margin-bottom: 6rem;
	}
	#room_tabs {
		font-family: var(--ff_italiana);
		li a {
			padding: 1rem 0;
			font-size: 1.4rem;
			font-size: clamp(1.1rem, 1.1vw, 1.6rem);
			white-space: nowrap;
		}
	}
	.img_blc {
		width: 60%;
		.swiper-slide {
			width: 100%;
			padding-top: 75%;
			@include g.imgcover();
		}
	}
	#topRoomSwiper {
		padding: 1rem;
		border: 1px solid var(--nansui_bk);
	}
}
@include g.mq(sp) {
	#top_room {
		.blockwrap {
			display: block;
		}
		.title {
			text-align: center;
		}
		.txt_blc {
			display: block;
			width: 100%;
			padding-right: 0;
			margin-bottom: var(--gap);
		}
		#room_tabs {
			li a {
				font-size: 1.4rem;
			}
		}	
		.img_blc {
			width: 100%;
			margin-bottom: 2rem;
		}
		#room_tabs {
			width: 100%;
			justify-content: center;
		}
		.moreblc {
			margin-top: var(--gap);
			margin-bottom: 0;
			text-align: center;
		}	
	}
}


/* ***** Stay **************** */
#top_stay {
	padding-bottom: 4rem;
	color: var(--pink_light);
	background: var(--green);
	overflow: hidden;
	.title_blc {
		display: flex;
		align-items: center;
		margin-bottom: 4rem;
		.title {
			margin-right: var(--side);
			margin-bottom: 0;
			.petal:before {
				transform: rotate(20deg);
			}
		}
		.txt {
			flex: 1;
		}
	}
	.more {
		margin-top: 2rem;
		color: var(--pink_light);
		border: 1px solid var(--pink_light);
		background: none;
		&:hover {
			color: var(--green);
			background: var(--pink_light);
		}
	}
	@include g.mq(sp) {
		.title_blc {
			display: block;
			text-align: center;
			.title {
				margin-right: 0;
				margin-bottom: var(--gap2);
			}
		}
		.moreblc {
			margin-top: 2rem;
			text-align: center;
		}
	}

	#topStaySwiper {
		overflow: visible;
		.swiper-wrapper {
			align-items: stretch;
			max-width: 110rem;
		}
		.swiper-slide {
			width: auto;
			height: auto;
			margin-right: 15%;
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;	
			.in {
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;	
			}
		}
		.txt {
			font-size: 1.2rem;
			.time {
				margin-bottom: 1rem;
				display: inline-block;
				font-family: var(--ff_zenmincho);
				font-size: 1.4rem;
				border-bottom: 1px solid var(--pink_light);
			}
			h3 {
				font-family: var(--ff_zenmincho);
				font-size: 2rem;
			}
		}
		#stay1 {
			width: 50%;
			.txt {
				margin-top: 2rem;
			}
		}
		#stay2 {
			width: 50%;
			justify-content: flex-end;
			.in {
				flex-direction: column-reverse;
				justify-content: flex-start;	
			}
			.txt {
				margin-bottom: 2rem;
			}
		}
		#stay3 {
			width: 70%;
			justify-content: center;
			.in {
				flex-direction: row-reverse;
				align-items: flex-end;	
			}
			img {
				width: calc(100% - 26rem);
			}
			.txt {
				width: 26rem;
				padding-right: 2rem;
			}
		}
		#stay4 {
			width: 50%;
			justify-content: flex-end;
			.in {
				flex-direction: column-reverse;
				justify-content: flex-start;	
			}
			.txt {
				margin-bottom: 2rem;
			}
		}
		#stay5 {
			width: 50%;
			justify-content: flex-end;
			padding-bottom: 10%;
			.in {
				flex-direction: row;
				align-items: flex-start;	
			}
			img {
				width: calc(100% - 26rem);
			}
			.txt {
				width: 26rem;
				padding-left: 2rem;
			}
		}
		#stay6 {
			width: 60%;
			justify-content: flex-end;
			padding-top: 10%;
			.in {
				flex-direction: row;
				align-items: flex-start;	
			}
			img {
				width: calc(100% - 26rem);
			}
			.txt {
				width: 26rem;
				padding-left: 2rem;
			}
		}
		#stay7 {
			width: 55%;
			justify-content: center;
			.txt {
				padding-top: 2rem;
			}
		}
		#stay8 {
			width: 70%;
			justify-content: center;
			.in {
				flex-direction: row-reverse;
				align-items: flex-end;	
			}
			img {
				width: calc(100% - 26rem);
			}
			.txt {
				width: 26rem;
				padding-right: 2rem;
			}
		}
		#stay9 {
			width: 50%;
			justify-content: flex-end;
			padding-bottom: 10%;
			.in {
				flex-direction: row;
				align-items: flex-end;
			}
			img {
				width: calc(100% - 26rem);
			}
			.txt {
				width: 26rem;
				padding-left: 2rem;
			}
		}
		#stay10 {
			width: 40%;
			justify-content: center;
			.txt {
				padding-top: 2rem;
			}
		}

	}

	#stay_nav {
		width: 100%;
		position: relative;
		@include g.flex();
		align-items: center;
		margin-top: 4rem;
		.stay_arw {
			line-height: 0;
			cursor: pointer;
			.arw path {
				fill: var(--pink_light);
			}
			&:hover {
				.arw path {
					fill: var(--beige_light);
				}
			}
		}
		#stay_prev {
			margin-right: 1rem;
			.arw {
				transform: rotate(180deg);
			}
		}
		#stay_next {
			margin-right: 2rem;
		} 
		.swiper-horizontal > .swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-horizontal {
			position: relative;
			top:auto;left: auto;right:auto;bottom:auto;
			height: 1px;
			width: auto;
			flex:1;
		}
		.swiper-scrollbar {
			border-radius: 1px;
			background: var(--pink_light);
		}
		.swiper-scrollbar-drag {
			height: 6px;
			background: var(--pink_light);
			border-radius: 3px;
			left: 0;
			top: -3px;
		}
	}

	@include g.mq(sp) {
		#topStaySwiper {
			.swiper-slide {
				margin-right: 15%;
			}
			.txt {
				line-height: 1.8;
				.time {
					font-size: 1.4rem;
				}
				h3 {
					margin-bottom: 1rem;
					font-size: 1.8rem;
				}
			}	
			#stay1 {
				width: 85%;
			}
			#stay2 {
				width: 85%;
				justify-content: center;
			}
			#stay3 {
				width: 85%;
				.in {
					flex-direction: column;
				}
				img {
					width: 100%;
				}
				.txt {
					width: 100%;
					padding-right: 0;
					margin-top: 2rem;
				}
			}
			#stay4 {
				width: 85%;
				justify-content: center;
			}
			#stay5 {
				width: 60%;
				img {
					width: 70%;
				}
				.txt {
					width: 100%;
					padding-left: 0;
					padding-bottom: 2rem;
				}
			}
			#stay6 {
				width: 80%;
				.in {
					flex-direction: column-reverse;
				}
				img {
					width: 100%;
					margin-left: auto;
				}
				.txt {
					width: 100%;
					padding-left: 0;
					padding-bottom: 2rem;
				}
			}
			#stay7 {
				width: 85%;
				justify-content: center;
				.txt {
					padding-top: 2rem;
				}
			}
			#stay8 {
				width: 70%;
				.in {
					flex-direction: column-reverse;
				}
				img {
					width: 100%;
				}
				.txt {
					width: 100%;
					padding: 0;
					padding-bottom: 2rem;
				}
			}
			#stay9 {
				width: 60%;
				justify-content: center;
				padding-bottom: 0;
				.in {
					align-items: flex-start;
				}
				img {
					width: 100%;
				}
				.txt {
					width: 100%;
					padding: 0;
					padding-top: 2rem;
				}
			}
			#stay10 {
				width: 70%;
				justify-content: center;
				.in {
					flex-direction: column-reverse;
				}
				.txt {
					padding-bottom: 2rem;
				}
			}
		}
	}
}


/* ***** Facilities **************** */
#top_facilities {
	@include g.gradebg();
	#top_facility_title {
		text-align: center;
		.petal:before {
			transform: rotate(-20deg);
		}	
	}
	.moreblc {
		margin-top: 2rem;
	}
	.facility {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		flex-wrap: wrap;
		padding-bottom: 10px;
		h2 {
			font-size: 3.2rem;
			strong {
				font-size: 6rem;
			}
		}
	}
}
@include g.mq(sp) {
	#top_facilities {
		.facility {
			flex-direction: column-reverse;
			padding-bottom: var(--gap);
			.title {
				text-align: center;
			}
			h2 {
				font-size: 2.4rem;
				strong {
					font-size: 4.4rem;
				}
			}
			.moreblc {
				text-align: center;
			}
		}
	}
}

/* ===== Restaurant ===== */
#top_restaurant {
	padding-top: 10px;
	.txt_blc {
		width: 100%;
		margin-bottom: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		.title,
		.txt {
			width: 50%;
			margin: 0;
			padding: var(--gap2);
			padding-right: 0;
		}
		.title {
			padding: 0;
			padding-right: var(--gap2);
		}
		.more {
			background: rgba(225, 223, 208, 0.5);
			&:hover {
				color: var(--beige_light);
				background: var(--nansui_bk);
			}
		}
	}
	.img_blc {
		width: 100%;
		.img {
			width: 100%;
			padding-top: 50%;
			@include g.imgcover();
		}
	}
}

@include g.mq(sp) {
	#top_restaurant {
		.txt_blc {
			margin-bottom: 0;
			.title,
			.txt {
				width: 100%;
				padding: 0;
			}
			.title {
				margin: 2rem 0;
			}
			.txt:before {
				display: none;
			}
			.img {
				padding-top: 60%;
			}
		}
	}
}

/* ===== Banquet ===== */
#top_banquet {
	margin-top: 10px;
	.txt_blc {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 35%;
		padding: var(--gap2);
		padding-left: 0;
	}
	.img_blc {
		width: 65%;
		.img {
			height: 100%;
			padding-top: 50%;
			@include g.imgcover();
		}
	}
}
@include g.mq(sp) {
	#top_banquet {
		.txt_blc {
			width: 100%;
			padding: 0;
			.title {
				margin: 2rem 0;
			}
		}
		.img_blc {
			width: 100%;
			.img {
				padding-top: 60%;
			}
		}
	}
}


/* ===== Okyakuba ===== */
#top_okyakuba {
	margin-top: var(--sec);
	.inner {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		flex-wrap: wrap;
		min-height: 400px;
		padding: 10px;
		padding-right: 0;
		background: #fff;
		&:hover {
			background: var(--beige_light);
		}
	}
	.img_blc,
	.txt_blc {
		width: 50%;
	}
	.img_blc {
		.img {
			height: 100%;
			padding-top: 60%;
			@include g.imgcover();
		}
	}
	.txt_blc {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: var(--gap2);
		.title {
			text-align: center;
		}
		h2 {
			padding-top: 2rem;
			overflow: hidden;
			img {
				max-width: 200px;
				margin: 0 auto;
				transform: translate(0,120%);
				transition: 1.2s 0.6s cubic-bezier(0.16, 1, 0.3, 1);
			}
		}
		.active h2 img {
			transform: translate(0,0);
		}
		.txt {
			max-width: 500px;
			margin: 0 auto;
		}
	}
}
@include g.mq(sp) {
	#top_okyakuba {
		margin-top: 1rem;
		.inner {
			min-height: 0;
			padding: 1rem;
		}
		.img_blc,
		.txt_blc {
			width: 100%;
		}
		.txt_blc {
			padding: 0;
			.title {
				margin: 0;
				padding: 3rem 0;
			}
			h2 {
				padding: 0;
				img {
					max-width: 16rem;
				}
			}
			.txt {
				max-width: 100%;
			}
		}
	}
}


/* ***** News **************** */
#top_news {
	.blockwrap {
		@include g.flex();
	}
	.title_blc {
		width: 35%;
		.petal:before {
			transform: rotate(-60deg);
		}
	}
	.more {
		background: var(--beige_light2);
		&:hover {
			background: var(--nansui_bk);
		}
	}
	.news_list {
		width: 65%;
	}
}
@include g.mq(sp) {
	#top_news {
		padding-bottom: 6rem;
		.title_blc {
			width: 100%;
			text-align: center;
		}
		.news_list {
			width: 100%;
		}
		.moreblc {
			width: 100%;
			margin-top: var(--gap2);
			text-align: center;
		}
	}
}


}