@use "global" as g; 

//================
// Room
//================
#room {

/* ***** Room wrapper **************** */
#room_wrapper {
	@include g.gradebg();
	#floatbtn {
		width: 24rem;
		position: fixed;
		bottom: 2rem;
		right: 2rem;
		background: #fff;
		z-index: 9;
		&:hover {
			background: var(--nansui_bk);
		}
	}
}
@include g.mq(sp) {
	#room_wrapper {
		#floatbtn {
			width: 14rem;
			height: 5rem;
			font-size: 1.6rem;
		}
	}
}


/* ***** Title **************** */
#title_sec {
	padding-bottom: var(--gap);
	.title {
		text-align: center;
		.petal:before {
			transform: rotate(-60deg);
		}
	}
	.tab_list li a {
		padding: 0;
		white-space: nowrap;
	}
	.col_blc {
		@include g.flex();
		.col {
			width: 50%;
		}
		.col1 {
			font-family: var(--ff_zenmincho);
			font-size: 1.6rem;
		}
		.col2 {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
		}
	}	
}
@media screen and (max-width: 1024px) {
	#title_sec {
		padding-bottom: var(--sec);
		.col_blc {
			.col {
				width: 100%;
			}
			.col1 {
				margin-bottom: var(--gap);
				font-size: 1.4rem;
				br.pc {
					display: none;
				}
			}
			.col2 {
				justify-content: center;
			}
		}	
	}	
}


/* ***** Room **************** */
#room_sec {
	padding-top: 0;
}
.room {
	@include g.flex();
	margin-bottom: var(--sec);
	padding-top: var(--gap2);
	.txt_blc {
		width: 35%;
		padding-right: var(--gap2);
		h2 {
			margin-bottom: var(--gap2);
			font-family: var(--ff_italiana);
			font-size: 4.4rem;
			line-height: 1;
		}
		.txt {
			margin-bottom: var(--gap);
		}
		.detail {
			line-height: 1.6;
			li {
				@include g.flex();
				padding: 1rem 0;
				.tit {
					width: 12rem;
				}
				.spec {
					width: calc(100% - 12rem);
					padding-left: 2rem;
				}
			}
			.info {
				margin-top: var(--gap2);
				p + p {
					margin-top: 2rem;
				}
			}
		}
	}
	.img_blc {
		width: calc(65% + var(--side));
		margin-right: calc(var(--side) * -1);
		overflow: hidden;
		.in {
			width: calc(100% - var(--side));
		}
		.roomswiper {
			overflow: visible;
		}
	}
}
@media screen and (max-width: 1024px) {
	.room {
		flex-direction: column-reverse;
		padding-top: 0;
		&:before{
			display: none;
		}
		.txt_blc {
			width: 100%;
			padding-right: 0;
			margin: 0;
			margin-top: var(--gap2);
			h2 {
				margin-bottom: 2rem;
				font-size: 3.6rem;
				text-align: center;
			}
			.txt {
				margin-bottom: 2rem;
			}
			.detail {
				li {
					.tit {
						width: 9rem;
					}
					.spec {
						width: calc(100% - 9rem);
						padding-left: 2rem;
					}
				}
				.info {
					margin-top: 2rem;
				}
			}
		}
		.img_blc {
			width: calc(100% + var(--side) * 2);
			margin: 0 calc(var(--side) * -1);
			overflow: hidden;
			.in {
				width: 100%;
			}
			.swiper_nav {
				justify-content: center;
				margin-top: 2rem;
				.swiper-pagination {
					margin: 0;
				}
				.prev_arw,.next_arw {
					display: none;
				}
			}
		}
	}
}

.suite_imgs {
	@include g.flex();
	padding-bottom: var(--sec);
	.suite1 {
		width: 35%;
		margin-left: 5%;
	}
	.suite2 {
		width: 30%;
		margin: 0 auto;
		margin-top: 30%;
		&.video {
			padding-top: 30%;
			video {
				height: 100%;
				width: auto;
			}
		}
	}
}
@media screen and (max-width: 1024px) {
	.suite_imgs {
		.suite1 {
			width: 60%;
		}
		.suite2 {
			width: 50%;
			margin: 0;
			margin-top: 20%;
			margin-left: 50%;
			&.video {
				padding-top: 50%;
			}	
		}
	}
}


/* ***** information **************** */
#information {
	.info_blc {
		margin-bottom: var(--gap2);
		&:last-child {
			margin-bottom: 0;
		}
		h2 {
			padding: var(--gap2) 0;
			font-family: var(--ff_italiana);
			font-size: 4.4rem;
			line-height: 1;
		}
		.in {
			@include g.flex();
			align-items: flex-end;
			padding: 0 var(--side);
			ul {
				width: 100%;
			}
		}
		.imgs {
			@include g.flex();
			width: 70%;
			.col1 {
				width: calc((100% - 2rem) / 3*2 + 1rem);
			}
			.col2 {
				width: calc((100% - 2rem) / 3);
				.img + .img {
					margin-top: 1rem;
				}
			}
			.img {
				width: 100%;
				padding-top: 100%;
				@include g.imgcover();
			}
		}
		.txt_blc {
			width: 30%;
			padding-left: var(--gap2);
			li {
				padding: 1rem 0;
				font-size: 1.2rem;
				line-height: 1.6;
				h3 {
					margin-bottom: 5px;
					font-family: var(--ff_zenmincho);
					font-size: 1.6rem;
				}
			}
		}
	}
	#info {
		li {
			@include g.flex();
			padding: 1rem 0;
			.tit {
				width: 27rem;
			}
			.spec {
				width: calc(100% - 27rem);
				padding-left: 2rem;
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	#information {
		.info_blc {
			.in {
				align-items: stretch;
				padding: 0;
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	#information {
		.info_blc {
			h2 {
				text-align: center;
			}
			.in {
				padding: 0;
			}
			.imgs {
				width: 100%;
				margin-bottom: 2rem;
				.col1 {
					width: calc((100% - 1rem) / 3*2 + 0.5rem);
				}
				.col2 {
					width: calc((100% - 1rem) / 3);
					.img + .img {
						margin-top: 0.5rem;
					}
				}
				.img {
					width: 100%;
					padding-top: 100%;
					@include g.imgcover();
				}
			}	
			.txt_blc {
				width: 100%;
				padding-left: 0;
				li {
					h3 {
						font-size: 1.4rem;
					}
				}
			}
		}
		#info {
			li {
				@include g.flex();
				padding: 1rem 0;
				.tit {
					width: 100%;
				}
				.spec {
					width: 100%;
					padding: 0;
				}
			}
			.bdr:after {
				display: none;
			}
		}
	}
}


}








