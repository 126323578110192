@use "global" as g; 

//================
// Concept
//================
#concept {

/* ***** Concept wrapper **************** */
#concept_wrapper {
	background: linear-gradient(180deg, #FFFFFF 0%, #F7F5EC 12.5%, #E1DFD0 25%, #F7F5EC 37.5%, #FFFFFF 50%, #F7F5EC 62.5%, #E1DFD0 75%, #F7F5EC 87.5%, #FFFFFF 100%);
}

#concept_title_sec {
	padding-bottom: 0;
}
#concept_sec {
	padding-top: 0;
}
@include g.mq(sp) {
	#concept_title_sec {
		.blockwrap {
			@include g.flex();
			flex-direction: column;
			padding: 0;
		}
		.title {
			order: 1;
		}
		#main_img {
			order: 2;
		}
		.concept_txt {
			order: 3;
		}
	}
}

/* ***** Title **************** */
.title {
	text-align: center;
	.petal:before {
		transform: rotate(30deg);
	}
}
.concept_txt {
	@include g.flex();
	max-width: 1250px;
	margin: 0 auto;
	margin-bottom: var(--sec);
	padding: 0 var(--side);
	font-family: var(--ff_zenmincho);
	font-size: 1.6rem;
	.col {
		width: 50%;
	}
	.col1 {
		line-height: 1.6;
		h2 {
			margin-bottom: 2rem;
			font-size: 2.8rem;
		}
	}
}
@include g.mq(sp) {
	.concept_txt {
		margin-bottom: 0;
		font-size: 1.4rem;
		.col {
			width: 100%;
		}
		.col1 {
			margin-bottom: var(--gap);
			h2 {
				margin-bottom: 2rem;
				font-size: 2.2rem;
			}
		}
	}
}


/* ***** Main img **************** */
#main_img {
	margin-bottom: var(--sec);
	padding-top: 50%;
	@include g.mq(sp) {
		margin-bottom: var(--gap);
	}
}


/* ***** Concept block **************** */
.concept_blc {
	@include g.flex();
	margin-bottom: var(--gap);
	.txt_blc {
		position: relative;
		width: 50rem;
		padding: var(--gap2);
		z-index: 1;
		.sub {
			font-family: var(--ff_italiana);
			font-size: 1.6rem;
			line-height: 1.6;
		}
		h3 {
			margin-bottom: 2rem;
			font-family: var(--ff_zenmincho);
			font-size: 2.6rem;
			line-height: 1.6;
		}
	}
}
@include g.mq(sp) {
	.concept_blc {
		margin-bottom: var(--gap);
		.txt_blc {
			width: 100%;
			padding: 0;
			h3 {
				font-size: 2.2rem;
			}
		}
	}
}

/* ===== Concept 1 ===== */
#concept1 {
	margin-bottom: 0;
	.txt_blc {
		order: 2;
		margin: 0 auto;
		margin-top: 10%;
	}
	#img1_1 {
		order: 1;
		width: 25%;
	}
	#img1_2 {
		order: 3;
		width: 30%;
		margin-top: 5%;
	}
	#img1_3 {
		order: 4;
		width: 25%;
		margin-top: 10%;
		margin-left: 20%;
	}
}
@media screen and (max-width: 1280px) {
	#concept1 {
		.txt_blc {
			margin-top: 5%;
		}
		#img1_2 {
			margin-top: -15%;
			margin-left: 5%;
		}
		#img1_3 {
			margin-top: 20%;
		}
	}
}
@media screen and (max-width: 960px) {
	#concept1 {
		#img1_2 {
			margin-left: -5%;
		}
		#img1_3 {
			margin-top: 10%;
		}
	}
}
@media screen and (max-width: 840px) {
	#concept1 {
		.txt_blc {
			margin-left: 30%;
		}
	}
}
@include g.mq(sp) {
	#concept1 {
		.txt_blc {
			order: 2;
			margin: 0 auto;
			margin-top: 10%;
		}
		#img1_1 {
			width: 70%;
			margin-left: auto;
			margin-top: 20%;
		}
		#img1_2 {
			width: 55%;
			margin-top: 20%;
			margin-left: 45%;
		}
		#img1_3 {
			width: 50%;
			margin-top: 30%;
			margin-left: 10%;
		}
	}
}

/* ===== Concept 2 ===== */
#concept2 {
	margin-top: -10%;
	.txt_blc {
		order: 2;
		margin-left: 10%;
		margin-right: auto;
	}
	#img2_1 {
		order: 1;
		width: 40%;
	}
	#img2_2 {
		order: 3;
		width: 30%;
		margin-top: -15%;
		margin-left: auto;
	}
}
@media screen and (max-width: 1320px) {
	#concept2 {
		#img2_2 {
			margin-top: 0;
		}
	}
}
@media screen and (max-width: 1170px) {
	#concept2 {
		margin-top: 0;
		.txt_blc {
			margin-left: auto;
		}
		#img2_1 {
			margin-left: -5%;
		}
		#img2_2 {
			width: 15%;
			margin-right: -5%;
		}
	}
}
@media screen and (max-width: 940px) {
	#concept2 {
		margin-top: var(--gap);
		.txt_blc {
			width: 70%;
			margin-right: -5%;
		}
		#img2_2 {
			width: 20%;
			margin-top: 10%;
			margin-right: 0;
		}
	}
}
@include g.mq(sp) {
	#concept2 {
		margin-top: 0;
		.txt_blc {
			order: 1;
			margin-left: 0;
			margin-right: 0;
		}
		#img2_1 {
			order: 2;
			width: 70%;
			margin-top: 30%;
			margin-left: calc(var(--side) * -1);
		}
		#img2_2 {
			width: 60%;
			margin-top: 20%;
		}
	}
}

/* ===== Concept 3 ===== */
#concept3 {
	margin-top: -25%;
	.txt_blc {
		order: 2;
		margin: 0 auto;
	}
	#img3_1 {
		order: 1;
		width: 100%;
	}
	#img3_2 {
		order: 3;
		width: calc(100% - 55rem);
		margin-top: 5%;
	}
	#img3_3 {
		order: 4;
		width: 40%;
		margin-top: 5%;
		margin-left: 10%;
	}
}
@media screen and (max-width: 1200px) {
	#concept3 {
		#img3_3 {
			margin-top: -5%;
			margin-left: 50%;
		}
	}
}
@media screen and (max-width: 940px) {
	#concept3 {
		margin-top: -15%;
		.txt_blc {
			margin-top: 5%;
		}
		#img3_2 {
			width: 30%;
		}
		#img3_3 {
			width: 45%;
		}
	}
}
@include g.mq(sp) {
	#concept3 {
		margin-top: -40%;
		.txt_blc {
			margin: 0 auto;
			margin-top: 10%;
		}
		#img3_1 {
			width: calc(100% + var(--side) * 2);
			margin: 0 calc(var(--side) * -1);
		}
		#img3_2 {
			order: 3;
			width: 70%;
			margin-top: 10%;
			margin-left: auto;
			margin-right: calc(var(--side) * -1);
		}
		#img3_3 {
			width: 60%;
			margin-top: 15%;
			margin-left: 0;
		}
	}
}


/* ***** Main img **************** */
#profile_blc {
	@include g.flex();
	padding: 1rem 0;
	.profile {
		@include g.flex();
		width: 50%;
		padding: var(--gap2);
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
		}
		.img {
			width: 20rem;
		}
		.txt {
			flex:1;
			padding-left: var(--gap2);
			font-size: 1.2rem;
			line-height: 1.6;
			.role {
				font-family: var(--ff_italiana);
				font-size: 2.2rem;
			}
			.sub {
				margin-bottom: 2rem;
				font-size: 1.2rem;
			}
			.name {
				font-family: var(--ff_zenmincho);
				font-size: 2.6rem;
			}
		}
	}
	@include g.mq(pc) {
		.bdb:after {
			display: none;
		}
	}
}
@media screen and (max-width: 940px) {
	#profile_blc {
		.profile {
			.img {
				margin-bottom: 2rem;
			}
			.txt {
				width: 100%;
				flex: auto;
				padding-left: 0;
			}
		}
	}
}
@include g.mq(sp) {
	#profile_blc {
		@include g.flex();
		padding: 0;
		.profile {
			display: block;
			width: 100%;
			padding: 2rem 0;
			.img {
				width: 16rem;
				margin: 0 auto;
				margin-bottom: 2rem;
			}
			.txt {
				width: 100%;
				padding-left: 0;
				text-align: center;
				.role {
					font-size: 1.6rem;
				}
				.sub {
					font-size: 1.2rem;
				}
				.name {
					font-size: 2.2rem;
				}
			}
		}
		.bdl:before {
			display: none;
		}
	}
}


}








