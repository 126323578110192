@use "global" as g; 

//================
// Footer common banner
//================
#common_banner {
	padding-bottom: calc(var(--sec) - 2rem);

/* ***** Reserve **************** */
.col_blc {
	@include g.flex();
	padding: 1rem 0;
	.col {
		width: 35%;
		padding: var(--gap2);
		&.left {
			padding-left: 0;
		}
		&.right {
			padding-right: 0;
		}
		&.wide {
			width: 65%;
		}
	}
	.title {
		width: 35%;
		margin-bottom: 0;
		font-size: 1.4rem;
		text-align: left;
		.tit {
			padding-bottom: 0;
			font-size: 4.4rem;
		}
		.petal:before {
			transform: rotate(45deg);
		}
	}
	.links {
		text-align: center;
		.blc + .blc {
			margin-top: 2rem;
		}
		p {
			margin-bottom: 1rem;
		}
	}
	.btnbox.wh {
		border: 0;
		background: #fff;
		&:hover {
			background: var(--nansui_bk);
		}
	}
}

}


@include g.mq(sp) {
	#common_banner {
		/* ***** Reserve **************** */
		.col_blc {
			.col {
				width: 100%;
				padding: 3rem 0;
				&.left {
					padding-bottom: 0;
					padding-bottom: 2rem;
				}
				&.right {
					padding-top: 0;
				}
				&.wide {
					width: 100%;
				}
			}
			.bdl:before {
				display: none;
			}
			.title {
				width: 100%;
				text-align: center;
			}
			.links {
				p {
					font-size: 1.2rem;
				}
			}
		}	
	}
}








