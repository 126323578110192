@use "global" as g; 

//================
// Contact
//================

.grecaptcha-badge { visibility: hidden; }
.googlerecapcha {
	font-size: 1.2rem;
	line-height: 1.4;
	a {
		text-decoration: underline;
	}
}
@include g.mq(sp) {
	.googlerecapcha {
		font-size: 1rem;
	}
}

#contact {

/* ***** bg wrapper **************** */
.bg_wrapper {
	@include g.gradebg();
}

/* ***** contents section **************** */
.contents_sec {
	.title {
		text-align: center;
	}
	.tx {
		font-family: var(--ff_zenmincho);
		font-size: 1.6rem;
		text-align: center;
		line-height: 1.6;
		@include g.mq(sp) {
			font-size: 1.4rem;
		}		
	}
	.sec_blc {
		max-width: 90rem;
		margin: 0 auto;
		padding-bottom: var(--sec);
	}
}
@include g.mq(sp) {
	.contents_sec {
		.sec_bdb {
			width: calc(100% + var(--side)*2);
			margin: 0 calc(var(--side)*-1);
		}
	}
}


/* ***** Title section **************** */
#title_sec {
	padding-bottom: 0;
	.title {
		text-align: center;
		.petal:before {
			transform: rotate(-120deg);
		}
	}
	#contact_nav {
		.tab_list {
			justify-content: center;
			font-family: var(--ff_zenmincho);
		}
	}
	@include g.mq(sp) {
		#contact_nav {
			padding-top: var(--gap);
			.tab_list {
				a {
					margin: 0;
				}
			}
		}
	}
}


/* ***** reservation **************** */
#reservation {
	padding-bottom: 0;
	text-align: center;
	background: none;
	.blc + .blc {
		margin-top: var(--gap2);
	}
	.blc {
		p {
			margin-bottom: 1rem;
		}
		.btnbox {
			background: #fff;
			&:hover {
				background: var(--nansui_bk);
			}
		}
	}
}
@include g.mq(sp) {
	#reservation {
		.tx {
			font-size: 1.2rem;
		}
	}
}

/* ***** contact_info **************** */
#contact_info {
	padding-bottom: 0;
	@include g.gradebg_reverse();
	.title {
		margin-bottom: var(--gap2);
	}
	.title + .txt {
		margin-bottom: var(--gap);
	}
}

#form_blc {	
	input,
	textarea,
	select,
	button {
		width: 100%;
		height: 4rem;
		display: flex;
		align-items: center;
		position: relative;
		margin: 1rem 0;
		margin-left: 1rem;
		padding: 0 1rem;
		font-family: var(--ff_zengaku);
		font-style: normal;
		font-size: 1.4rem;
		color: var(--nansui_bk);
		border: 0;
		background: none;
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		-ms-appearance: none;
		border-radius: 0;
	}
	textarea {
		height: auto;
	}
	::placeholder {
		color: rgba(0,0,0,0.6);
	}
	::-ms-input-placeholder {
		color: rgba(0,0,0,0.6);
	}
	:-ms-input-placeholder {
		color: rgba(0,0,0,0.6);
	}
	label a {
		text-decoration: underline;
		&:hover {
			opacity: 0.6;
		}
	}
	input[type=submit],
	button[type=submit] {
		height: 6rem;
		margin: 0;
		margin-top: var(--gap2);
		@include g.flex_center();
		font-family: var(--ff_italiana);
		font-size: 2.4rem;
		letter-spacing: 0.1em;
		color: var(--nansui_bk);
		background: #fff;
		border: 1px solid var(--nansui_bk);
		@include g.tra();
		cursor: pointer;
		span {
			margin-left: 2rem;
			font-family: var(--ff_zenmincho);
			font-size: 1.4rem;
		}
		&:hover {
			color: var(--beige_light);
			background: var(--nansui_bk);
		}
		&:disabled {
			pointer-events: none;
			cursor: not-allowed;
		}
	}	
	
	/* ===== selsect ===== */
	.select {
		.in {
			position: relative;
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				right: 1rem;
				width: 1rem;
				height: 1rem;
				border-right: 1px solid var(--nansui_bk);
				border-bottom: 1px solid var(--nansui_bk);
				transform: translateY(-50%) rotate(45deg);
			}
		}
	}

	/* ===== checkbox / radio ===== */	
	.checkbox,.radio {
		@include g.flex_center();
		input[type="radio"],
		input[type="checkbox"] {
			width: 2rem;
			height: 2rem;
			position: relative;
			margin: 0;
			padding: 0;
			border: 1px solid var(--nansui_bk);
			&:before {
				content:'';
				width: 110%;
				height: 50%;
				position: absolute;
				top: 50%;
				left: 50%;
				border-left: 1px solid #000;
				border-bottom: 1px solid #000;
				transform: translate(-30%,-100%) rotate(-45deg);
				opacity: 0;
				@include g.tra();
			}
			&:checked:before {
				opacity: 1;
			}
		}
		label {
			margin-left: 2rem;
		}
	}

	
	/* ===== CF7 ===== */	
	.wpcf7 form {
		.wpcf7-acceptance {
			@include g.flex_center();
		}
		.checkbox,.radio {
			p {
				margin-left: 2rem;
				a {
					text-decoration: underline;
				}
			}
		}
		.wpcf7-form-control-wrap {
			position: relative;
		}
		.wpcf7-not-valid-tip {
			position: absolute;
			top: 100%;
			left: 2rem;
			font-size: 1.2rem;
			line-height: 1;
		}
		.wpcf7-response-output {
			margin: 0;
			padding: 0;
			border: 0;
			font-size: 1.2rem;
			color: #dc3232;
		}
		&.sent {
			.wpcf7-response-output {
				color: #2d9c2d;
			}
		}
	}



	.blc {
		width: 100%;
		@include g.flex();
		align-items: center;
		padding: 1rem 0;
		label {
			width: 20rem;
			font-family: var(--ff_zengaku);
			margin-bottom: 5px;
		}
		.inpt {
			flex: 1;
		}
	}
	.agreebtn {
		margin-top: var(--gap2);
		.inpt {
			flex: none;
			margin: 0 auto;
			white-space: nowrap;
		}
	}

	p.res {
		padding-top: 1rem;
		text-align: center;
	}	
}

@include g.mq(sp) {
	#form_blc {	
		input,
		textarea,
		select,
		button {
			height: auto;
			margin: 0;
			margin-left: 0;
			padding: 0;
		}
		button[type=submit] {
			margin-top: 2rem;
		}
		.blc {
			label {
				width: 100%;
			}
			.inpt {
				width: 100%;
				flex:none;
			}
		}
		.bdl:before {
			display: none;
		}
		.agreebtn {
			margin-top: 2rem;
			justify-content: center;
			.inpt {
				width: auto;
			}
		}		
	}
}
	
/* ***** FAQ **************** */
#faq {
	padding-bottom: 0;
	@include g.gradebg();
	#faq_nav {
		.tab_list {
			justify-content: center;
		}
	}
	#faq_blc_wrap {
		padding-bottom: var(--sec);
		.faq {
			@include g.flex();
			padding-top: var(--gap);
			.tit {
				width: 22rem;
				font-family: var(--ff_italiana);
				font-size: 2.6rem;
				line-height: 1;
			}
			.bd:before {
				z-index: 1;
			}
			.faq_blc {
				width: calc(100% - 22rem);
				.exbtn {
					padding: 2rem 0;
					font-weight: 700;
					p {
						margin-right: auto;
						padding-right: 1rem;
						flex: 1;
					}
					@include g.mq(pc) {
						&:hover {
							background: #fff;
						}
					}
				}
				.exc {
					.txt {
						flex: 1;
						padding-bottom: 2rem;
					}
				}
				.exbtn:before,
				.exc:before {
					content:'Q';
					display: inline-block;
					margin-right: 2rem;
					font-weight: 700;
				}
				.exc:before {
					content:'A';
				}
			}
		}
	}
}
@include g.mq(sp) {
	#faq {
		padding-bottom: 0;
		@include g.gradebg();
		#faq_nav {
			.tab_list {
				justify-content: center;
				flex-direction: column;
				li {
					margin-bottom: 1rem;
					border: 0;
					a {
						font-size: 1.6rem;
					}
				}
			}
		}
		#faq_blc_wrap {
			.faq {
				.tit {
					width: 100%;
					margin-bottom: 2rem;
					font-size: 2.2rem;
				}
				.faq_blc {
					width: 100%;
				}
			}
		}
	}
}


/* ***** Access **************** */
#access {
	padding-bottom: 0;
	@include g.gradebg_reverse();
	.map {
		width: 100%;
		position: relative;
		padding-top: 56.25%;
		iframe {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			filter: grayscale(100%);
		}
	}
	#route {
		margin-top: var(--gap2);
		li {
			@include g.flex();
			padding: 2rem 0;
			.tit {
				width: 30%;
				padding: 2rem 0;
				font-family: var(--ff_italiana);
				font-size: 2.6rem;
				line-height: 1;
			}
			.txt {
				width: 70%;
				padding: 2rem 0;
				padding-left: var(--gap2);
			}	
		}
	}
}
@include g.mq(sp) {
	#access {
		.blockwrap {
			padding: 0;
		}
		.map {
			padding-top: 75%;
		}	
		#route {
			width: calc(100% - var(--side) * 2);
			margin: 0 auto;
			margin-top: var(--gap2);
			li {
				@include g.flex();
				padding: 2rem 0;
				.tit {
					width: 100%;
					margin-bottom: 2rem;
					padding: 0;
					font-size: 2.2rem;
				}
				.txt {
					width: 100%;
					padding: 0;
				}	
			}
			.bdr:after {
				display: none;
			}
		}
	}
}


}










