@use "global" as g; 

//================
// Footer
//================
#footer {
	padding: 2rem;
	color: var(--beige_light);
	a {
		color: var(--beige_light);
		svg .cls {
			fill: var(--beige_light);
		}
		&:hover {
			color: var(--nansui_red);
			svg .cls {
				fill: var(--nansui_red);
			}
		}
	}
	.inner {
		@include g.flex();
		justify-content: flex-start;
		padding: calc(var(--side) - 2rem);
		padding-bottom: 2rem;
		background: var(--nansui_bk);
		border-radius: 1rem;
	}
	#footer_info {
		width: 50%;
		max-width: calc(100% - 54rem);
		padding-right: calc(var(--side) - 2rem);
		#footer_logo {
			max-width: 240px;
			margin-bottom: 4rem;
			line-height: 0;
		}
		.txt {
			margin-bottom: 2rem;
			font-size: 1.2rem;
			strong {
				font-family: var(--ff_italiana);
				font-size: 2.4rem;
				letter-spacing: 0.2em;
			}
		}
		#footer_map {
			width: 100%;
			position: relative;
			padding-top: 60%;
			iframe {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				filter: grayscale(100%);
			}
		}
	}
	#footer_nav {
		width: 50%;
		min-width: 54rem;
		@include g.flex_center();
		padding-left: calc(var(--side) - 2rem);
		.nav_set_blc {
			width: 50%;
			max-width: 100%;
		}
		.subnav {
			a {
				border-color: var(--beige_light);
			}
		}
		.enpage {
			a {
				.arw .cls {
					fill: var(--beige_light);
				}
				&:hover {
					.arw .cls {
						fill: var(--nansui_red);
					}
				}	
			}
		}
		.btnbox {
			border-color: var(--beige_light);
			&:hover {
				color: var(--nansui_bk);
				background: var(--beige_light);
			}
		}
		.reserve {
			.btnbox {
				color: var(--nansui_bk);
				background: var(--beige_light);
				&:hover {
					color: var(--beige_light);
					background: var(--nansui_red);
				}
			}	
		}
	}
	#copyright {
		width: 100%;
		padding-top: 2rem;
		font-family: var(--ff_italiana);
		font-size: 1.2rem;
		letter-spacing: 0.2em;
		text-align: center;
	}


	@media only screen and (max-width: 1200px) {
		#footer_info {
			padding-right: 2rem;
		}
		#footer_nav {
			padding-left: 2rem;
		}
	}
	@media only screen and (max-width: 1023px) {
		#footer_info {
			width: 100%;
			max-width: 100%;
			padding-right: 0;
			margin-bottom: var(--side);
		}
		#footer_nav {
			width: 100%;
			min-width: 0;
			padding-left: 0;
		}
	}
	@include g.mq(sp) {
		.inner {
			padding: 4rem var(--side);
		}	
		#footer_info {
			margin-bottom: 4rem;
			#footer_logo {
				max-width: 200px;
			}
			.txt {
				strong {
					font-size: 2rem;
				}
			}
		}
		#footer_nav {
			display: block;
			.nav_set_blc {
				width: 100%;
			}
			.nav2 {
				padding-bottom: 0;
			}
		}
	}
}








