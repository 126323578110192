@use "global" as g; 

/* ***** video **************** */
iframe,
video {
	filter: drop-shadow(0px 0px #000);
	outline: none;
	border: none;
}
.video {
	width: 100%;
	padding-top: 56.25%;
	position: relative;
	overflow: hidden;
	iframe,
	video {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
}

// .video_bg {
// 	width: 100%;
// 	height: 100%;
// 	overflow: hidden;
// 	position: absolute;
// 	right: 0;
// 	top: 0;
// 	bottom: 0;
// 	left: 0;
// 	iframe {
// 		aspect-ratio: 16 / 9;
// 		width: auto;
// 		height: 100%;
// 		position: absolute;
// 		top: 50%;left: 50%;
// 		transform: translate(-50%,-50%);
// 		@media (min-aspect-ratio: 16/9) {
// 			width: 100%;
// 			height: auto;
// 		}
// 	}
// }
.video_bg {
	width: 100%;
	height: 100%;
	video {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;left: 0;
		object-fit: cover;
	}
}

/* ***** swiper **************** */
.swiper {
	height: 100%;
	.swiper_nav {
		display: flex;
		align-items: center;
		margin-top: 1rem;
		.prev_arw,
		.next_arw {
			margin-left: 1rem;
			line-height: 0;
			cursor: pointer;
			&:hover {
				.arw path {
					fill: var(--nansui_red);
				}
			}
		}
		.prev_arw .arw {
			transform: rotate(180deg);
		}
		.swiper-pagination {
			width: auto;
			position: relative;
			top: auto;left: auto;right: auto;bottom: auto;
			margin-right: 2rem;
			text-align: left;
			line-height: 0;
		}
		.swiper-pagination-bullet-active {
			background: var(--nansui_bk);
		}
		.swiper-pagination-bullet {
			background: var(--nansui_bk);
		}
	}

}


/* ===== Border ===== */
.bd {
	position: relative;
}
.bdt:before,
.bdb:after,
.bdl:before,
.bdr:after {
	content: '';
	display: block;
	position: absolute;
	background: var(--nansui_bk);
	transition: transform 1.2s cubic-bezier(0.16, 1, 0.3, 1), background 0.3s;
	pointer-events: none;
}
.bdt:before,
.bdb:after {
	width: 100%;
	height: 1px;
	transform: scale(0,1);
	transform-origin: left;
}
.bdl:before,
.bdr:after {
	width: 1px;
	height: 100%;
	transform: scale(1,0);
	transform-origin: top;
}
.bdt:before {
	top: 0;
	left: 0;
}
.bdb:after {
	left: 0;
	bottom: 0;
}
.bdl:before {
	top: 0;
	left: 0;
}
.bdr:after {
	top: 0;
	right: 0;
}
.bd.active:before,
.bd.active:after {
	transform: scale(1);
}


/* ===== petal ===== */
.petal {
	display: inline-block;
	pointer-events: none;
	opacity: 0;
	transform: rotate(-360deg);
	transition: 1.2s cubic-bezier(0.16, 1, 0.3, 1);
}
.active .petal {
	opacity: 1;
	transform: rotate(0deg);
}
.petal:before {
	content:'';
	display: block;
	width: 2rem;
	height: 2.4rem;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(../img/petal.svg);
}

/* ===== title ===== */
.title {
	margin-bottom: 6rem;
	font-family: var(--ff_zenmincho);
	line-height: 1.2;
	opacity: 1 !important;
	.tit {
		padding: 1rem 0;
		font-family: var(--ff_italiana);
		font-size: 6rem;
		.petal:before {
			opacity: 0;
			transition: 0.3s;
		}
		&.active .petal:before {
			opacity: 1;
		}
	}
	&.mini {
		.tit {
			font-size: 4.4rem;
		}	
	}
}
.pages .title {
	margin-bottom: var(--gap);
}
@include g.mq(sp) {
	.title {
		margin-bottom: 3rem;
		font-size: 1.2rem;
		.tit {
			font-size: 4.4rem;
		}
	}
}


/* ===== Parallax image ===== */
.fullimg {
	padding: 0;
}
.paraimg {
	width: 100%;
	padding-top: 40%;
	position: relative;
	overflow: hidden;
	.in {
		width: 100%;
		padding-top: 66.66%;
		position: absolute;
		top: 0;left: 0;
	}
	img {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		object-fit: cover;
	}
}
.paraimg_side {
	width: 100%;
	padding-top: 70%;
	.in {
		width: 120%;
		height: 100%;
		padding-top: 0;
	}
}
@include g.mq(sp) {
	.paraimg {
		padding-top: 50%;
		.in {
			padding-top: 70%;
		}
	}
	.paraimg_side {
		padding-top: 70%;
		.in {
			padding-top: 0;
		}
	}
}


/* ===== Split text ===== */
.split .char {
	display: inline-block;
	overflow: hidden;
	vertical-align: bottom;
}
.split .char span {
	display: inline-block;
}


/* ===== Arrow ===== */
.arw {
	width: 2.8rem;
	height: 2.8rem;
	@include g.mq(sp) {
		width: 2.2rem;
		height: 2.2rem;
	}
	.cls {
		@include g.tra();
	}
}


.btnbox {
	@include g.flex_center();
	flex-wrap: wrap;
	flex-direction: column;
	height: 10rem;
	font-family: var(--ff_italiana);
	font-size: 3.6rem;
	text-align: center;
	line-height: 1;
	border: 1px solid var(--nansui_bk);
	span {
		width: 100%;
		display: inline-block;
		margin-top: 5px;
		font-family: var(--ff_zenmincho);
		font-size: 1.4rem;
	}
	&:hover {
		background-color: var(--nansui_bk);
		color: var(--beige_light);
	}
	&.l {
		font-size: 3.2rem;
		span {
			font-size: 1.2rem;
		}
	}
	&.m {
		height: 7.4rem;
		font-size: 2.2rem;
		span {
			font-size: 1.2rem;
		}
	}
	&.s {
		flex-direction: row;
		height: 4.4rem;
		font-size: 1.8rem;
		span {
			width: auto;
			margin: 0;
			margin-left: 1rem;
			font-family: var(--ff_zenmincho);
			font-size: 1.2rem;
		}
	}
	&.tel {
		@include g.mq(pc) {
			pointer-events: none;
		}
		p {
			width: 100%;
			font-family: var(--ff_zenmincho);
			font-size: 1.4rem;
		}
		strong {
			width: 100%;
			@include g.flex_center();
		}
		span {
			width: auto;
			font-size: 1.2rem;
			margin-right: 1rem;
		}
	}
}
@include g.mq(sp) {
	.btnbox {
		font-size: 2.8rem;
		&.l {
			height: 6rem;
			font-size: 2.2rem;
		}
	}
}

/* ***** More **************** */
.more {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 20rem;
	height: 3.8rem;
	font-family: var(--ff_italiana);
	font-size: 1.2rem;
	background: rgba(255,255,255,0.5);
	&:hover {
		color: var(--beige_light);
		background: var(--nansui_bk);
	}
}


/* ***** Tab list **************** */
.tab_list {
	display: flex;
	align-items: center;
	font-family: var(--ff_italiana);
	li {
		line-height: 0;
		border-right: 1px solid var(--nansui_bk);
		&:first-child {
			border-left: 1px solid var(--nansui_bk);
		}
		a {
			margin: 0 1rem;
			padding: 1rem;
			font-size: 1.6rem;
			line-height: 1;
			cursor: pointer;
			@include g.mq(sp) {
				padding: 0 1rem;
				font-size: 1.4rem;
			}
			&.on {
				background: var(--beige_light2);
			}
			&:hover {
				@include g.mq(pc) {
					background: var(--beige_light2);
				}
			}
		}
	}
}


/* ***** Expand **************** */
.ex {
	width: 100%;
	.exbtn {
		width: 100%;
		display: flex !important;
		@include g.flex();
		@include g.tra();
		line-height: 1.4;
		cursor: pointer;
		position: relative;
		.plus {
			width: 1rem;
			height: 1rem;
			position: relative;
			margin-top: 0.5rem;
			&:before,&:after {
				content: '';
				display: block;
				width: 100%;
				height: 1px;
				position: absolute;
				top: 50%;
				left: 50%;
				background: #000;
				transform: translate(-50%,-50%);
				@include g.tra();
			}
			&:after {
				transform: translate(-50%,-50%) rotate(90deg);
			}
		}
	}
	.exc {
		@include g.flex();
		height: 0;
		padding: 0;
		overflow: hidden;
		p {
			width: 100%;
		}
	}
	&.open > {
		.exbtn {
			.plus {
				&:after {
					transform: translate(-50%,-50%) rotate(0deg);
				}
			}
		}
	}
}


