@use "global" as g; 

//================
// 404
//================
#notfound {

#not_sec {
	padding: 0;
	#bgpetal {
		width: 100%;
		height: 100vh;
		overflow: hidden;
		.dot {
			width:2rem;
			height:2.4rem;
			position:absolute;
			@include g.bg();
			background-image: url(../img/petal.svg);
			background-size: contain;
		}
	}
	
	#not_blc {
		width: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1;
		@include g.flex_center();
		flex-direction: column;
		text-align: center;
		transform: translate(-50%, -50%);
		.title {
			margin-bottom: 0;
			text-align: center;
		}
		p {
			font-family: var(--ff_zenmincho);
			font-size: 1.6rem;
			@include g.mq(sp) {
				font-size: 1.4rem;
			}			
		}
		.more {
			margin-top: var(--gap2);
			border: 1px solid var(--nansui_bk);
		}
	}

	#kamon {
		width: 12rem;
		margin: 0 auto;
		margin-bottom: var(--gap2);
		@include g.mq(sp) {
			width: 10rem;
		}
		#kamon_svg {
			position: relative;
		}
		.cls_petal,
		.frame path,
		#cir {
			opacity: 0;
		}
	}

}


}








