
$breakpoints: (
	'sp': 'screen and (max-width: 767px)',
	'pc': 'screen and (min-width: 768px)',
) !default;

@mixin mq($breakpoint) {
	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}

:root {
	--nansui_bk: #3E3A39;
	--nansui_red: #E8380D;
	--pink_light: #F8CABC;
	--green: #023532;
	--beige_light: #F7F5EC;
	--beige_light2: #E1DFD0;

	--ff_zengaku: "Zen Kaku Gothic New", sans-serif;
	--ff_zenmincho: "Zen Old Mincho", serif;
	--ff_italiana: "Italiana", sans-serif;

	--side: 7.5rem;
	--sec: 12rem;
	--gap: 7.5rem;
	--gap2: 4rem;
}
@media only screen and (max-width: 767px) {	
	:root {	
		--side: 2rem;
		--sec: 8rem;
		--gap: 4rem;
		--gap2: 4rem;
	}
}

@mixin tra() {
	transition: 0.3s;
}
@mixin flex() {
	display:flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: stretch;	
}
@mixin flex_center() {
	display:flex;
	justify-content: center;
	align-items: center;
}
@mixin bg() {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
@mixin imgcover() {
	width: 100%;
	position: relative;
	img {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		object-fit: cover;	
	}
}
@mixin bfaf() {
	content:'';
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

@mixin gradebg() {
	background: linear-gradient(to bottom, #fff 0%, var(--beige_light) 50%, var(--beige_light2) 100%);
}
@mixin gradebg_reverse() {
	background: linear-gradient(to top, #fff 0%, var(--beige_light) 50%, var(--beige_light2) 100%);
}

