@use "global" as g; 

//================
// Nav
//================
body {
	--navcolor: var(--nansui_bk);
	--navlogocolor: var(--nansui_bk);
}
body.navwh.scrltop {
	--navcolor: var(--beige_light);
	--navlogocolor: #fff;
}
body.navwh.scrltop.navopen {
	--navcolor: var(--nansui_bk);
	--navlogocolor: var(--nansui_bk);
}

#nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	body.scrl & {
		background: rgba(255,255,255,0.8);
	}
	body.scrl.navopen & {
		background: none;
	}
	@include g.tra();

	#header_nav {
		width: 100%;
		position: relative;
		@include g.flex();
		align-items: center;
		padding: 1rem 3rem;
		z-index: 99;
	}
	#nav_logo {
		@include g.flex_center();
		width: 18rem;
		a {
			line-height: 0;
			&:hover {
				opacity: 0.6;
			}
		}
		.typo path {
			fill: var(--navlogocolor);
			transition: 0.3s;
		}
	}
	#nav_reserve {
		margin-left: auto;
		a {
			height: 4.4rem;
			@include g.flex_center();
			padding: 0 3rem;
			font-family: var(--ff_italiana);
			font-size: 1.2rem;
			color: var(--navcolor);
			border: 1px solid var(--navcolor);
			.pc {
				padding-right: 10px;
				font-size: 1.6rem;
			}
		}
		.petal {
			display: inline-block;
			margin-right: 10px;
			opacity: 1;
			&:before {
				width: 15px;
				height: 18px;
				transform: rotate(45deg);
				transition: 0.3s;
			}
		}
		a:hover {
			background: var(--pink_light);
			.petal:before {
				transform: rotate(-45deg);
			}
		}
	}
	.nav_lang {
		@include g.flex_center();
		margin: 0 2rem;
		color: var(--navcolor);
		.slash {
			margin: 0 0.5rem;
		}
		a {
			color: var(--navcolor);
			opacity: 0.4;
			&:hover {
				opacity: 1;
			}
		}
		.nav_lang_jp {
			opacity: 1;
			pointer-events: none;
		}
		html[lang="en-US"] & {
			.nav_lang_jp {
				opacity: 0.4;
				pointer-events: auto;
				&:hover {
					opacity: 1;
				}
			}
			.nav_lang_en {
				opacity: 1;
				pointer-events: none;
			}
		}
	}
	#nav_btn {
		width: 4.4rem;
		height: 2rem;
		position: relative;
		cursor: pointer;
		&:before,
		&:after {
			@include g.bfaf();
			height: 1px;
			position: absolute;
			top: 50%;
			left: 50%;
			background: var(--navcolor);
			@include g.tra();
		}
		&:before {
			transform: translate(-50%,calc(-50% - 5px));
		}
		&:after {
			transform: translate(-50%,calc(-50% + 5px));
		}
		&:hover {
			&:before {
				transform: translate(-50%,calc(-50% - 8px));
			}
			&:after {
				transform: translate(-50%,calc(-50% + 8px));
			}
		}
		body.navopen & {
			&:before {
				transform: translate(-50%,-50%) rotate(30deg);
			}
			&:after {
				transform: translate(-50%,-50%) rotate(-30deg);
			}
		}
	}
}	

@include g.mq(sp) {
	#nav {	
		#header_nav {
			padding: 1.5rem;
		}
		#nav_logo {
			width: 12rem;
		}
		#nav_reserve {
			a {
				height: 2.6rem;
				padding: 0 1rem;
			}
			.petal {
				margin-right: 8px;
				&:before {
					width: 10px;
					height: 12px;
				}
			}
		}
		.nav_lang {
			margin: 0 1rem;
			font-size: 1rem;
			.slash {
				margin: 0;
			}
		}	
		#nav_btn {
			width: 3.6rem;
			&:before {
				transform: translate(-50%,calc(-50% - 4px));
			}
			&:after {
				transform: translate(-50%,calc(-50% + 4px));
			}
			&:hover {
				&:before {
					transform: translate(-50%,calc(-50% - 6px));
				}
				&:after {
					transform: translate(-50%,calc(-50% + 6px));
				}
			}
		}
	}	
}	


//================
// Main Nav
//================
#main_nav {
	@include g.flex();
	width: 100%;
	height: 100vh;
	height: 100dvh;
	position: absolute;
	top: 0;
	left: 0;
	@include g.gradebg();
	transition: 0.6s cubic-bezier(0.215, 0.610, 0.355, 1);
	opacity: 0;
	pointer-events: none;
	body.navopen & {
		opacity: 1;
		pointer-events: auto;
	}
	#nav_img {
		@include g.imgcover();
		width: 50%;
		max-width: calc(100% - 60rem);;
		height: 100%;
		position: relative;
		img {
			opacity: 0;
		}
		@include g.mq(sp) {
			display: none;
		}
	}
	#main_nav_blc {
		width: 50%;
		min-width: 60rem;
		height: 100%;
		@include g.flex_center();
		padding: var(--side) 2rem;
		padding-top: 10rem;
		overflow: auto;
		@media only screen and (max-height: 740px) {
			align-items: stretch;
		}
		@include g.mq(sp) {
			width: 100%;
			min-width: 0;	
			padding: 4rem;
			padding-top: 10rem;
		}
	}
}

body.navopen {
	overflow: hidden;
}

/* ***** Nav block ********* */
.nav_blc {
	width: 100%;
	@include g.flex();
	justify-content: center;
	font-family: var(--ff_italiana);
	line-height: 1;
	svg .cls {
		@include g.tra();
	}
	a:hover {
		color: var(--nansui_red);
		&.btnbox {
			color: var(--beige_light);
		}
		svg .cls {
			fill: var(--nansui_red);
		}
	}
	.nav1 {
		width: 100%;
		max-width: 24rem;
		position: relative;
		li {
			margin-bottom: 3rem;
			font-size: 3.2rem;
			&:last-child {
				margin-bottom: 0;
			}
			span {
				display: block;
				font-family: var(--ff_zenmincho);
				font-size: 1.2rem;
			}
		}
		#nav_petal {
			position: absolute;
			top: 0;
			left: -3rem;
			transform: translateZ(0);
			&:before {
				width: 1.5rem;
				height: 1.8rem;	
			}
		}
	}
	.nav2 {
		width: 100%;
		max-width: 26rem;
		.blc {
			margin-bottom: 4rem;
			&:last-child {
				margin-bottom: 0;
			}
		}
		.subnav {
			li {
				width: 100%;
				margin-bottom: 2rem;
				&:last-child {
					margin-bottom: 0;
				}
			}
			a {
				display: block;
				padding-bottom: 1rem;
				border-bottom: 1px solid var(--nansui_bk);
				font-size: 2.6rem;
				span {
					display: inline-block;
					padding-left: 2rem;
					font-size: 1.2rem;
					font-family: var(--ff_zenmincho);
				}	
			}
		}
		.enpage {
			a {
				display: flex;
				align-items: center;
				font-size: 1.8rem;
				.arw {
					margin-left: 1rem;
				}	
			}
		}
		.contact {
			.tel {
				margin-bottom: 2rem;
				font-size: 3.2rem;
				@include g.mq(pc) {
					a {
						pointer-events: none;
					}
				}		
				span {
					display: block;
					font-size: 1.2rem;
				}	
			}
		}
		.links {
			li {
				margin-bottom: 1rem;
			}
			a {
				font-size: 1.2rem;
				text-decoration: underline;
			}
		}
	}
}

@include g.mq(sp) {
	.nav_blc {
		.nav1 {
			max-width: 100%;
			margin-bottom: 2rem;
			ul {
				@include g.flex();
			}
			li {
				width: 50%;
				font-size: 2.4rem;
			}
			#nav_petal {
				display: none;
				left: -2rem;
				&:before {
					width: 1rem;
					height: 1.2rem;	
				}
			}
		}
		.nav2 {
			max-width: 100%;
			padding-bottom: 4rem;
			.subnav {
				a {
					font-size: 2rem;
					span {
						font-size: 1.2rem;
					}	
				}
			}
		}
	}
}

