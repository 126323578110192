@use "global" as g; 

//================
// Facility
//================
#facility {

/* ***** Facility wrapper **************** */
#facility_wrapper {
	@include g.gradebg();
}

/* ***** Title **************** */
#title_sec {
	padding-bottom: 0;
	.blockwrap {
		@include g.flex();
		align-items: flex-start;
	}
	.title_blc {
		width: 20rem;
		.title {
			.petal:before {
				transform: rotate(-60deg);
			}
		}	
	}
	.facility_nav_blc {
		width: calc(100% - 20rem);
		max-width: 800px;
		margin-top: var(--gap2);
		#facility_tabs {
			flex-wrap: wrap;
			justify-content: flex-end;
			align-items: flex-start;
			font-family: var(--ff_zenmincho);
			li {
				margin-left: -1px;
				margin-bottom: 1rem;
				border-left: 1px solid var(--nansui_bk);
			}
		}
	}
}
@include g.mq(sp) {
	#title_sec {
		.title_blc {
			width: 100%;
		}
		.facility_nav_blc {
			width: 100%;
			max-width: 100%;
			margin-top: 0;
			padding-bottom: var(--gap2);
			#facility_tabs {
				justify-content: flex-start;
				li {
					margin-bottom: 2rem;
					a {
						margin: 0;
					}
				}
			}
		}
	}
}


/* ***** Facility item **************** */
--facility_img_gap: 1rem;
@include g.mq(sp) {
	--facility_img_gap: 0.5rem;
}

#facility_sec {
	padding-top: 0;
}
.item {
	@include g.flex();
	align-items: flex-start;
	padding: var(--gap2) 0;
	.in {
		width: 100%;
		@include g.flex();
		align-items: center;
	}
	.img_blc,
	.txt_blc {
		width: calc((100% - 1rem) / 2);
	}
	.img_blc {
		.img {
			width: 100%;
			height: 100%;
			padding-top: 67%;
			@include g.imgcover();
		}
		.colimg {
			width: 100%;
			@include g.flex();
			.col {
				width: calc((100% - var(--facility_img_gap)) / 2);
				&:first-of-type {
					margin-bottom: var(--facility_img_gap);
				}
			}
		}
	}
	.txt_blc {
		padding-left: var(--gap2);
		.tit {
			display: flex;
			align-items: center;
			margin-bottom: var(--gap2);
			.floor {
				@include g.flex_center();
				width: 2.4rem;
				height: 4rem;
				margin-right: 2rem;
				font-size: 1.2rem;
				line-height: 1;
				border: 1px solid var(--nansui_bk);
				border-radius: 1.2rem;
				span {
					font-size: 1rem;
				}
			}
			h2 {
				font-family: var(--ff_zenmincho);
				font-size: 2.6rem;
				line-height: 1.6;
			}
		}
		.more {
			margin-top: var(--gap2);
			font-family: var(--ff_zenmincho);
			border: 1px solid var(--nansui_bk);
			background: none;
			&:hover {
				background: var(--nansui_bk);
			}
		}
		.more + .more {
			margin-left: 1rem;
		}
	}
	&#okyakuba h2 {
		max-width: 18rem;
	}

	/* === type_a === */
	&.type_a {
		.img_blc {
			.wide {
				width: 100%;
			}
		}
	}

	/* === type_b === */
	&.type_b {
		.img_blc {
			.col.col1 {
				width: calc(70% - var(--facility_img_gap));
				margin-bottom: 0;
				.img {
					padding-top: 125%;
				}
			}
			.col2 {
				width: 30%;
				.col {
					width: 100%;
					height: calc((100% - var(--facility_img_gap)) / 2);
					.img {
						height: 100%;
					}
				}
			}
		}
	}

	/* === type_c === */
	&.type_c {
		.img_blc,
		.txt_blc {
			width: 100%;
			padding: 0;
		}
		.img_blc {
			@include g.flex();
			margin-bottom: var(--gap2);
			.colimg {
				.col1 {
					margin-bottom: 0;
				}
				.in {
					width: calc((100% - var(--facility_img_gap)) / 2);
					.col:nth-of-type(2) {
						margin-bottom: var(--facility_img_gap);
					}
				}
			}
		}
	}
	
	/* === Reverse === */
	&:nth-of-type(even){
		.in {
			flex-direction: row-reverse;
		}
		.txt_blc {
			padding: 0;
			padding-right: var(--gap2);
		}
	}
	&:nth-of-type(odd){
		&.type_a {
			flex-direction: row-reverse;
			.txt_blc {
				padding-left: var(--gap2);
				padding-right: 0;
			}
		}
	}
}

#reception-lounge,
#bar-lounge,
#yugao-terrace {
	.img_blc img {
		object-position: bottom;
	}
}

@include g.mq(sp) {
	.item {
		.img_blc,
		.txt_blc,
		.sub_img_blc {
			width: 100%;
		}
		.img_blc {
			margin-bottom: 2rem;
		}	
		.txt_blc {
			padding-left: 0;
			.tit {
				margin-bottom: 2rem;
			}
			.more {
				display: flex;
				margin: 0 auto;
				margin-top: var(--gap2);
			}
			.more + .more {
				margin-top: 2rem;
				margin-left: auto;
			}	
		}
		&#okyakuba h2 {
			max-width: 14rem;
			margin: 0 auto;
		}	

		/* === type_c === */
		&.type_c {
			.img_blc,
			.txt_blc {
				width: 100%;
				padding: 0;
			}
			.img_blc {
				margin-bottom: 2rem;
				.colimg {
					.col1 {
						width: 100%;
						margin-bottom: var(--facility_img_gap);
					}
					.in {
						width: 100%;
					}
				}
			}
		}

		/* === Reverse === */
		&:nth-of-type(even){
			.in {
				flex-direction: row-reverse;
			}
			.txt_blc {
				padding: 0;
			}
		}
		&:nth-of-type(odd){
			&.type_a {
				.txt_blc {
					padding: 0;
				}
			}
		}
	}
}


/* ***** Map **************** */
#floormap {
	width: 80%;
	margin: 0 auto;
	margin-top: var(--sec);
}
@include g.mq(sp) {
	#floormap {
		width: 100%;
	}
}


}








