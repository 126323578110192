@use "global" as g; 

html {
	margin : 0;
	padding : 0;
	font-size:62.5%;
}
body {
	margin : 0;
	padding : 0;
	width : 100%;
	height:100%;
	font-family: var(--ff_zengaku);
	font-size: 1.4rem;
  font-weight: 400;
  font-style: normal;
	letter-spacing: 0.1em;
	color: var(--nansui_bk);
	line-height:2.2;
	z-index: 1;
	-webkit-text-size-adjust:none;
	background-color:var(--beige_light);
	-webkit-font-smoothing: antialiased;
}

html[lang=en-US] {
	body {
		line-height: 1.8;
	}
}

h1,h2,h3,h4,strong,em,b {
	font-weight: normal;
}
a {
	display: inline-block;
	color:var(--nansui_bk);
	text-decoration: none;
	word-break: break-word;
	@include g.tra();
}
img {
	width: 100%;
	height: auto;
	display: block;
	vertical-align: text-bottom;
}
li{
	list-style:none;
}
.svg-block {
  display: block;
}
object {
	pointer-events: none;
}
.svg-link object {
	overflow:visible;
}
svg {
	width: 100%;
	height: auto;
	overflow: visible;
}
::selection {
 background: var(--pink_light);
 color:var(--nansui_bk);
}
::-moz-selection {
 background: var(--pink_light);
 color:var(--nansui_bk);
}
form ::selection {
 background: var(--pink_light);
 color:var(--nansui_bk);
}
form ::-moz-selection {
 background: var(--pink_light);
 color:var(--nansui_bk);
}
:focus {
	outline: 0;
}


/* ***** Common **************** */
.pc { display: inline; }
.sp { display: none; }
@include g.mq(sp) {
	.pc { display: none; }
	.sp { display: inline; }
}
.pcblc { display: block; }
.spblc { display: none; }
@include g.mq(sp) {
	.pcblc { display: none; }
	.spblc { display: block; }
}

.nolink {
	opacity: 0.3;
	pointer-events: none;
}


/* ***** Layout **************** */
section {
	position: relative;
	padding: var(--sec) 0;
}
.blockwrap {
	position: relative;
	margin: 0 auto;
	padding: 0 var(--side);
}


