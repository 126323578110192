@use "global" as g; 

//================
// Stay
//================
#stay {

/* ***** Stay wrapper **************** */
#stay_wrapper {
	background: var(--green);
}

/* ***** Title **************** */
#title_sec {
	position: relative;
	padding: 0;
	#main_img {
		width: 100%;
		padding-top: 50%;
		@include g.imgcover();
		&:after {
			@include g.bfaf();
			background: rgba(0,0,0,0.2);
			mix-blend-mode: overlay;
		}
	}
	.stay_title {
		width: 100%;
		@include g.flex_center();
		padding: 0 var(--side);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: var(--beige_light);
		.col {
			width: 50%;
		}
		.col1 {
			margin: 0 auto;
			.title {
				margin-bottom: 0;
				text-align: center;
				.petal:before {
					transform: rotate(20deg);
				}
			}		
		}
		.col2 {
			font-family: var(--ff_zenmincho);
			font-size: 1.6rem;	
		}
	}
}
@include g.mq(sp) {
	#title_sec {
		padding: calc(var(--sec)*2) 0;
		#main_img {
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
		.stay_title {
			display: block;
			position: relative;
			top: auto;
			left: auto;
			transform: translate(0,0);
			text-align: center;
			.col {
				width: 100%;
			}
			.col1 {
				margin-bottom: var(--gap2);
			}
			.col2 {
				font-size: 1.4rem;	
			}
		}
	}
}


/* ***** Stay section **************** */
.stay_sec {
	padding-bottom: 0;
	color: var(--pink_light);
	.bd:after {
		background: var(--pink_light);
	}
	.sec_title {
		margin-bottom: var(--sec);
		font-family: var(--ff_zenmincho);
		font-size: 1.6rem;
		h2 {
			font-size: 2.8rem;
		}
	}
	@include g.mq(sp) {
		.sec_title {
			font-size: 1.4rem;
			h2 {
				font-size: 2.2rem;
			}
		}
	}

	.stay_item {
		margin-bottom: 5%;
		&:last-child {
			margin-bottom: 0;
		}
		.in {
			width: 100%;
			display: flex;
		}
		.img_blc {
			width: 40%;
		}
		.txt_blc {
			width: 30%;
			min-width: 40rem;
			padding: var(--gap2);
		}
		.time {
			display: flex;
			align-items: center;
			margin-bottom: 2rem;
			line-height: 1;
			&:before {
				content: '';
				display: inline-block;
				width: 2rem;
				height: 2rem;
				margin-top: 0.5rem;
				margin-right: 1rem;
				@include g.bg();
				background-size: contain;
				background-image: url(../img/timer.svg);
			}
			p {
				font-family: var(--ff_italiana);
				font-size: 3.2rem;
				span {
					margin-left: 1rem;
					font-family: var(--ff_zenmincho);
					font-size: 1.2rem;		
				}
			}
		}
		h3 {
			margin-bottom: 3rem;
			font-family: var(--ff_zenmincho);
			font-size: 2.6rem;
			line-height: 1;
			span {
				font-size: 1.4rem;
			}
		}
		.txt {
			line-height: 1.8;
		}
		.more {
			margin-top: 3rem;
			color: var(--pink_light);
			border: 1px solid var(--pink_light);
			background: none;
			&:hover {
				color: var(--green);
				background: var(--pink_light);
			}
		}		
	}
	@include g.mq(sp) {
		.stay_item {
			.in {
				flex-wrap: wrap;
			}
			.img_blc {
				width: 100%;
			}
			.txt_blc {
				width: 100%;
				min-width: 0;
				padding: 2rem 0;
			}
			.time {
				margin-bottom: 1rem;
				&:before {
					width: 1.5rem;
					height: 1.5rem;
				}
				p {
					font-size: 2.2rem;
					span {
						font-size: 1rem;		
					}
				}
			}	
			h3 {
				margin-bottom: 2rem;
				font-size: 2.2rem;
				span {
					font-size: 1.2rem;
				}	
			}
			.more {
				margin-top: 2rem;
			}
		}
	}


	/* === section 1 === */
	#stay1_1 {
		.in {
			align-items: center;
		}
		.img_blc {
			width: 40%;
		}
	}
	#stay1_2 {
		margin-top: -5%;
		.in {
			flex-direction: row-reverse;
			align-items: flex-end;	
		}
		.img_blc {
			width: 50%;
		}
	}
	#stay1_3 {
		margin-top: -5%;
		.in {
			align-items: center;
		}
		.img_blc {
			width: 40%;
		}
	}
	#stay1_4 {
		margin-bottom: 0;
		.in {
			flex-direction: row-reverse;
			align-items: flex-end;	
		}
		.img_blc {
			width: 50%;
		}
	}
	@media screen and (max-width: 1200px) {
		#stay1_2 {
			margin-top: 0;
			.in {
				align-items: center;
			}
			.txt_blc {
				margin-left: -5%;
			}
		}
		#stay1_3 {
			margin-top: -10%;
		}
		#stay1_4 {
			margin-top: -10%;
			.in {
				align-items: center;
			}
		}	
	}
	@media screen and (max-width: 900px) {
		#stay1_2 {
			.img_blc {
				width: 50%;
			}
			.txt_blc {
				margin-left: -5%;
			}
		}
		#stay1_3 {
			.img_blc {
				width: 60%;
			}
		}
		#stay1_4 {
			.img_blc {
				width: 70%;
			}
		}	
	}
	@include g.mq(sp) {
		#stay1_1 {
			width: 80%;
			.img_blc {
				width: 100%;
			}
		}
		#stay1_2 {
			width: 80%;
			margin-left: 20%;
			.in {
				flex-direction: row-reverse;
				align-items: flex-end;	
			}
			.img_blc {
				width: 100%;
			}
		}
		#stay1_3 {
			width: 90%;
			margin-top: -20%;
			.img_blc {
				width: 100%;
			}
		}
		#stay1_4 {
			width: 90%;
			margin: 0 auto;
			margin-top: -10%;
			.img_blc {
				width: 90%;
				margin-right: auto;
			}
		}
	}

	/* === section 2 === */
	#stay2_1 {
		.img_blc {
			width: 30%;
		}
	}
	#stay2_2 {
		margin-top: -10%;
		.in {
			flex-direction: row-reverse;
			align-items: flex-end;	
		}
		.img_blc {
			width: 45%;
		}
	}
	#stay2_3 {
		margin-left: var(--side);
		.in {
			align-items: center;
		}
		.img_blc {
			width: 60%;
		}
	}
	#stay2_4 {
		margin-right: var(--side);
		.in {
			flex-direction: row-reverse;
			align-items: flex-end;	
		}
		.img_blc {
			width: 45%;
		}
	}
	@media screen and (max-width: 1200px) {
		#stay2_2 {
			margin-top: 0;
			.in {
				align-items: center;
			}
		}
		#stay2_3 {
			margin-left: 0;
		}
		#stay2_4 {
			margin-right: 0;
			.in {
				align-items: center;
			}
			.img_blc {
				width: 60%;
			}	
		}	
	}
	@media screen and (max-width: 860px) {
		#stay2_1 {
			margin-bottom: 0;
		}
		#stay2_2 {
			margin-bottom: 0;
			.img_blc {
				width: 60%;
			}
		}
		#stay2_3 {
			margin-left: 0;
		}
	}
	@include g.mq(sp) {
		#stay2_1 {
			width: 70%;
			.img_blc {
				width: 100%;
			}
		}
		#stay2_2 {
			width: 80%;
			margin-left: auto;
			.img_blc {
				width: 100%;
			}
		}
		#stay2_3 {
			width: 80%;
			.img_blc {
				width: 100%;
			}
		}
		#stay2_4 {
			width: 70%;
			margin-left: auto;
			.img_blc {
				width: 100%;
			}
		}
	}

	/* === section 3 === */
	#stay3_1 {
		margin-left: var(--side);
		.in {
			align-items: center;
		}
		.img_blc {
			width: 30%;
		}
	}
	#stay3_2 {
		margin-bottom: 0;
		.in {
			flex-direction: row-reverse;
			align-items: flex-end;	
		}
		.img_blc {
			width: 45%;
		}
	}
	#stay3_3 {
		padding-bottom: var(--sec);
		margin-left: 30%;
		.in {
			align-items: center;
		}
		.img_blc {
			display: none;
		}
	}
	@media screen and (max-width: 1200px) {
		#stay3_1 {
			margin-top: -5%;
			.img_blc {
				width: 40%;
			}	
		}	
		#stay3_2 {
		}
	}
	@media screen and (max-width: 860px) {
		#stay3_1 {
			margin-left: 0;
		}	
		#stay3_2 {
			.img_blc {
				width: 70%;
			}
		}	
	}
	@include g.mq(sp) {
		#stay3_1 {
			width: 80%;
			.img_blc {
				width: 100%;
			}
		}
		#stay3_2 {
			width: 90%;
			margin-left: auto;
			margin-right: calc(var(--side) * -1);
			.img_blc {
				width: 100%;
			}
			.txt_blc {
				padding-right: 2rem;
			}
		}
		#stay3_3 {
			width: 90%;
			margin-top: -30%;
			margin-left: auto;
			margin-right: calc(var(--side) * -1);
			.img_blc {
				width: 100%;
			}
			.txt_blc {
				padding-right: 2rem;
			}
		}
	}

}


}








