@use "global" as g; 

//================
// Contents block
//================
.contents_block {
	h1,h2,h3,h4,h5,h6,ul,ol,p {
		padding-bottom: 20px;
	}
	h1,h2,h3,h4,h5,h6,strong {
		font-size: 1.8rem;
		font-weight: 500;
		line-height: 1.6;
	}
	ul,
	ol {
		padding-left: 2em;
	}
	ul li {
		list-style-type: disc;
	}
	ol li {
		list-style-type: decimal;
		ol li {
			list-style-type: lower-alpha;
			ol li {
				list-style-type: lower-roman;
			}
		}
	}
	figure {
		padding: 20px 0;
	}
	table td {
		padding: 10px;
		border-collapse: collapse;
		border:1px solid #333;
	}
	figure.wp-block-image {
		display: inline-block;
	}
	figcaption {
		display: block;
		margin: 0;
		padding-top: 5px;
	}
	blockquote {
		font-style: italic;
		color: #666;
	}
	a {
		display: inline-block;
		text-decoration: underline;
		color: #000;
	}
	a:hover {
		color: var(--tangerine);
	}
	.wp-block-file *+.wp-block-file__button {
		margin-left: 2em;
	}
	.wp-block-file .wp-block-file__button {
		padding: 1em 2em;
		padding-bottom: 0.8em;
		font-size: 1.2rem;
		line-height: 1;
		text-decoration: none;
		letter-spacing: 0.1em;
		color: #000;
		background: #FFF;
		border: 1px #000 solid;
		border-radius: 2em;
		transition: 0.3s;
	}
	.wp-block-file .wp-block-file__button:hover {
		color: #FFF;
		background: #000;
	}
}

